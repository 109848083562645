import moment from 'moment';

const formatStatus = (status) => {
  switch (status) {
    case 'Liberar para dealer':
      return 'Liberar para dealer';
    case 'aguardando_aprovacao_dealer':
      return 'Aguardando aceite dealer';
    case 'aprovacao_aceita_dealer':
      return 'Aprovação aceita dealer';
    case 'aprovacao_recusada_dealer':
      return 'Aprovação recusada dealer';
    case 'aprovacao_retida':
      return 'Aprovação retida';
    case 'aprovacao_pausada':
      return 'Aprovação pausada';
    case 'aprovacao_cancelada':
      return 'Aprovação cancelada';
    case 'enviado_para_cadastro':
      return 'Enviado para cadastro';
    case 'atualizar_documentos_dealer':
      return 'Atualizar documentos dealer';
    case 'documentos_atualizados_dealer':
      return 'Documentos atualizados dealer';
    case 'documentos_cadastrais_analise':
      return 'Cadastro em análise';
    case 'cadastro_atualizado':
      return 'Cadastro atualizado';
    case 'renovacao_cadastral':
      return 'Em renovação cadastral';
    case 'enviado_para_juridico':
      return 'Enviado para jurídico';
    case 'aguardando_assinatura_dealer':
      return 'Aguardando assinatura dealer';
    case 'contrato_assinado_digitalizado':
      return 'Contrato assinado digitalizado';
    case 'contratos_analise':
      return 'Contratos em análise';
    case 'contrato_digitalizado_validado':
      return 'Contrato digitalizado validado';
    case 'contrato_fisico_recebido':
      return 'Contrato recebido';
    case 'enviado_para_credito':
      return 'Enviado para crédito';
    case 'obs_do_credito':
      return 'Observações do crédito';
    case 'limite_implantado':
      return 'Limite implantado';
    case 'pendente_documentos':
      return 'Pendente docs. dealer';
    case 'documentos_enviados_parcialmente':
      return 'Docs. enviados parcialmente';
    case 'Pendente':
      return 'Pendente';
    case 'analise_financiamento_rede':
      return 'Análise Financiamento Rede';
    case 'docs_juridico_atualizado_dealer':
      return 'Docs. jurídico atualizados dealer';
    case 'docs_juridico_enviados_parcialmente':
      return 'Docs. jurídico enviados parcialmente';
    case 'pendente_docs_juridico_dealer':
      return 'Pendente docs. jurídico dealer';
    case 'pendente_docs_formalizar_dealer':
      return 'Pendente docs. para formalizar dealer';
    case 'pendente_docs_original_dealer':
      return 'Pendente docs. original dealer';
    case 'docs_somente_para_formalizar':
      return 'Docs. somente para formalizar';
    case 'docs_formalizar_enviados_sem_retorno':
      return 'Docs. para formalizar enviados - sem retorno';
    case 'docs_formalizar_atualizado_dealer':
      return 'Docs. para formalizar atualizado dealer';
    case 'docs_formalizar_enviados_parcialmente':
      return 'Docs.para formalizar enviados parcialmente';
    case 'atualizacao_docs_juridico':
      return 'Em atualização docs. jurídico';
    case 'assinatura_docusign':
      return 'Assinatura Docusign';
    case 'aprovacao_finalizada':
      return 'Aprovação finalizada';
    case 'analise_juridico':
      return 'Análise jurídico';
    case 'pendente_docs_complementares_dealer':
      return 'Pendente Docs. complementares dealer';
    case 'docs_somente_formalizar':
      return 'Docs. somente para formalizar';
    default:
      return status;
  }
};

export default formatStatus;

export const formatDate = (dataHora) => (
  moment(dataHora).format('DD-MM-YYYY')
);

export const formatValue = (value) => {
  const formattedValue = value?.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formattedValue;
};
