/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import axios from 'axios';

class LimitesAprovadosService {
  async getFilters() {
    const result = await axios.get(`${window.env.REACT_APP_API_URL}/formalizacao/gestaoLimites/filters`);
    if (!result.data) throw new Error('Empty data fetching filters');

    if (result.status === 200) {
      return result.data;
    }
  }

  async getHistoricoLimite(idLimite) {
    const result = await axios.get(`${window.env.REACT_APP_API_URL}/formalizacao/gestaoLimites/historico/${idLimite}`);
    if (!result.data) throw new Error('Empty data fetching filters');

    if (result.status === 200) {
      return result.data;
    }
  }

  async getLimitesAprovados(codigoBuc) {
    try {
      const response = await axios.get(`${window.env.REACT_APP_API_URL}/formalizacao/gestaoLimitesDealer/limites-aprovados`, {
        headers: {
          'fidis-dealer-id': codigoBuc,
        },
      });
      return response.data;
    } catch (e) {
      throw new Error('Falha ao buscar limites!');
    }
  }

  async updateAndSaveStatus(userName, dados) {
    try {
      const response = await axios.patch(`${window.env.REACT_APP_API_URL}/formalizacao/gestaoLimites/updateHistorico`, dados, {
        headers: {
          'fidis-user-name': userName,
        },
      });
      return response;
    } catch (e) {
      throw new Error('Falha ao alterar status!');
    }
  }
}

export default new LimitesAprovadosService();
