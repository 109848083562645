import { React } from 'react';
import PropTypes from 'prop-types';
import './infoCadastroList.scss';
import { Divider } from '@mui/material';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import TooltipMensagem from 'features/common/tooltipMensagem';
import SummaryPage from 'features/common/summaryPage';
import RadioGroupPessoa from './radioGroupPessoa/radioGroupPessoa';
import UploadDocumento from '../../uploadDocumento';

const InfoCadastroList = ({
  listaPessoaDocumentacao,
  gerenciadorDocumentos,
  statusLimite,
}) => {
  const renderItem = (text, item) => (
    <div className="modal-cadastro__content__documentos_itens">
      <span className="modal-cadastro__content__documentos__text-label">{text}</span>
      <div className="modal-cadastro__content__documentos__text-info">{item}</div>
    </div>
  );
  const subtitle = 'Insira os documentos referentes a atualização do cadastro da aprovação e em seguida clique em enviar para cadastro.';
  const getIdDocumento = (idTipoDocumento) => {
    const documento = listaPessoaDocumentacao?.documentos.find(
      (doc) => doc?.idTipoDocumento === idTipoDocumento,
    );
    return documento?.idPessoaDocumentacao;
  };

  const isAnexoRemovido = (tipoDocumento) => {
    const documento = listaPessoaDocumentacao?.documentos.find(
      (doc) => doc?.idTipoDocumento === tipoDocumento.value,
    );
    if (!documento
      && tipoDocumento.motivoRemocao?.find((motivoRemocao) => motivoRemocao !== null)) return true;
    return false;
  };

  const getDocumento = (idTipoDocumento, idPessoaDocumento) => gerenciadorDocumentos?.find(
    (doc) => doc?.idTipoDocumento === idTipoDocumento
      && doc?.idPessoaDocumento === idPessoaDocumento,
  );
  const statusFluxoCadastro = [
    'pendente_documentos',
    'documentos_enviados_parcialmente',
    'documentos_atualizados_dealer',
  ];
  const enableDeleteDocument = (idTipoDocumento, idPessoaDocumento) => {
    const documento = gerenciadorDocumentos?.find(
      (doc) => doc?.idTipoDocumento === idTipoDocumento
        && doc?.idPessoaDocumento === idPessoaDocumento,
    );
    return !documento?.validado && statusFluxoCadastro.includes(statusLimite);
  };

  const documentosIncompletos = () => !gerenciadorDocumentos.every((item) => item.nome);

  const renderIcon = () => {
    if (documentosIncompletos()) {
      return (
        <TooltipMensagem
          title="Há documentos pendentes para envio"
        >
          <ErrorOutlineIcon style={{ color: '#C31E10', width: 16, height: 16 }} />
        </TooltipMensagem>
      );
    }
    return (
      <TooltipMensagem
        title="Lista enviada"
      >
        <CheckRoundedIcon style={{ color: '#206446', width: 16, height: 16 }} />
      </TooltipMensagem>
    );
  };

  return (
    <SummaryPage
      title={listaPessoaDocumentacao?.pessoaDocumentacao.nome}
      IconTitle={renderIcon()}
      level={2}
    >
      <div className="modal-cadastro__content__sections">
        <div className="modal-cadastro__content_header">
          {renderItem('Relacionamento', listaPessoaDocumentacao?.pessoaDocumentacao.tipoRelacionamento.text)}
          <RadioGroupPessoa
            inputData={listaPessoaDocumentacao?.pessoaDocumentacao.tipoPessoa}
            disabled
          />
          {renderItem('CNPJ|CPF', listaPessoaDocumentacao?.pessoaDocumentacao.documento)}
        </div>
        <Divider />
        <div className="modal-cadastro__content__text-editor-title">
          <h1>Lista de documentos</h1>
          <span>
            {subtitle}
          </span>
        </div>
        {listaPessoaDocumentacao?.pessoaDocumentacao.tipoDocumento?.map((item, index) => (
          <>
            <div key={index} className="modal-cadastro__content__documentos">
              {renderItem('Nome do documento', item.text)}
              <UploadDocumento
                idDocumento={getIdDocumento(item?.value)}
                anexoRemovido={isAnexoRemovido(item)}
                documento={
                    getDocumento(
                      item?.value,
                      listaPessoaDocumentacao?.pessoaDocumentacao.idPessoaDocumentacao,
                    )
                  }
                showDeleteButton={enableDeleteDocument(
                  item?.value,
                  listaPessoaDocumentacao?.pessoaDocumentacao.idPessoaDocumentacao,
                )}
              />
            </div>
            {item.motivoRemocao?.find((motivoRemocao) => motivoRemocao !== null) && (
              <div className="modal-cadastro__content__text-editor">
                <div className="modal-cadastro__content__text-editor-title">
                  Observações anexo removido
                </div>
                {item.motivoRemocao?.map((motivo, key) => (
                  <p className="modal-cadastro__content__text-anexo" key={key}>{motivo}</p>
                ))}
              </div>
            )}
            <Divider />
          </>
        ))}
      </div>
    </SummaryPage>
  );
};

InfoCadastroList.propTypes = {
  nome: PropTypes.string,
  listaPessoaDocumentacao: PropTypes.object,
  gerenciadorDocumentos: PropTypes.array,
  statusLimite: PropTypes.string,
};

InfoCadastroList.defaultProps = {
  nome: '',
  statusLimite: '',
  listaPessoaDocumentacao: null,
  gerenciadorDocumentos: [],
};

export default InfoCadastroList;
