import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { styled, makeStyles } from '@material-ui/styles';
import FormControl from '@mui/material/FormControl';

import MarcaBadge from 'features/common-staff/views/marcaBadge';
import SearchLogo from '../../../../assets/imgs/search-icon.svg';

import DealerOption from '../dealerOption/dealerOption';

import './dealerSelector.scss';

const StyledFormControl = styled(FormControl)({
  display: 'block',
});

const StyledTextField = ({ ...props }) => (
  <TextField
    {...props}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: 'none',
        },
      },
    }}
  />
);

const useStyles = makeStyles({
  root: {
    '& .MuiFormControl-root': {
      display: 'block',
      verticalAlign: 'middle',
      bottom: 8,
      width: 175,
      height: 24,
    },
  },

  input: {
    '& .MuiInputLabel-animated': {
      transition: 'none',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
        borderRadius: 10,
      },
      '&:disabled fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
        borderWidth: '2',
      },
      '&.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.8)',
      },
    },
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 1)',
      right: '8px',
      '&.Mui-focused': {
        color: 'transparent',
      },
    },
  },

  paper: {
    marginLeft: '-40px',
    borderRadius: '10px',
    width: '253px',
    boxShadow: '0 5px 10px 0 rgba(138, 149, 158, 0.2)',
  },

  option: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
});

const DealerSelector = ({
  dealers, selectedDealer, setDealer,
  isLoadingCarteira, isLoadingMovimentacoes, isLoadingSimulador, isLoadingFidc,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const onSelectChange = useCallback((_event, value) => {
    if (dealers.some((d) => d.dealerId === value.dealerId)) {
      setDealer(value.dealerId);
      setIsOpen(false);
    }
  }, [dealers, setDealer]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const options = dealers.filter((d) => d.dealerId);
  const isLoading = isLoadingCarteira || isLoadingMovimentacoes
    || isLoadingSimulador || isLoadingFidc;

  return (
    <div
      className="dealer-selector"
      data-cy="DealerSelector"
    >
      <div className="dealer-selector-area">
        {isOpen === true
          ? (
            <StyledFormControl>
              <div className="dealer-selector-icon-search">
                <img src={SearchLogo} alt="brand-logo" />
              </div>
              <div className="dealer-select">
                <Autocomplete
                  options={options}
                  onChange={onSelectChange}
                  disableClearable
                  noOptionsText="Nenhuma concessionária encontrada"
                  onClose={close}
                  blurOnSelect="mouse"
                  filterOptions={createFilterOptions({
                    matchFrom: 'any',
                    stringify: (option) => (option?.dealerName || ' ') + (option?.dealerId || ' '),
                  })}
                  getOptionLabel={(option) => (
                    option.dealerName || selectedDealer.dealerName
                  )}
                  classes={{
                    root: classes.root,
                    input: classes.input,
                    paper: classes.paper,
                    option: classes.option,
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.dealerIde}>
                      <DealerOption dealer={option} />
                    </li>
                  )}
                  renderInput={(params) => (
                    <StyledTextField
                      disabled={isLoading}
                      onBlur={close}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      InputLabelProps={{ shrink: false }}
                      placeholder="Pesquisar"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </div>
            </StyledFormControl>
          )
          : (
            <StyledFormControl>
              <div className="dealer-selector-icon">
                <MarcaBadge
                  marca={(selectedDealer?.brand)}
                />
              </div>
              <div className="dealer-select">
                <Autocomplete
                  disabled={isLoading}
                  disableClearable
                  value={selectedDealer.dealerName || 'Dealer'}
                  options={[]}
                  getOptionLabel={(option) => (
                    option.dealerName || selectedDealer.dealerName
                  )}
                  onOpen={open}
                  classes={{
                    root: classes.root,
                    input: classes.input,
                  }}
                  data-cy="DealerSelected"
                  renderInput={(params) => {
                    const paramsUpdate = {
                      ...params,
                      inputProps: { ...params.inputProps, value: `${selectedDealer.dealerCorr} • ${selectedDealer.dealerName}` },
                    };
                    return (
                      <StyledTextField
                        onBlur={close}
                        {...paramsUpdate}
                        variant="outlined"
                        fullWidth
                        title={`${selectedDealer.dealerCorr} • ${selectedDealer.dealerName}`}
                      />
                    );
                  }}
                />
              </div>
            </StyledFormControl>
          )}
      </div>
    </div>
  );
};

DealerSelector.propTypes = {
  dealers: PropTypes.array.isRequired,
  selectedDealer: PropTypes.object,
  setDealer: PropTypes.func.isRequired,
  isLoadingCarteira: PropTypes.bool,
  isLoadingMovimentacoes: PropTypes.bool,
  isLoadingSimulador: PropTypes.bool,
  isLoadingFidc: PropTypes.bool,
};

DealerSelector.defaultProps = {
  selectedDealer: {},
  isLoadingCarteira: false,
  isLoadingMovimentacoes: false,
  isLoadingSimulador: false,
  isLoadingFidc: false,
};

export default DealerSelector;
