import _ from 'lodash';
import ResgateAceites from 'modules/resgates';
import Tracking from '../../tracking';
import Dealers from '../../dealers';
import { DealerOperations } from '../../dealers/redux';

import * as Auth from '../auth';
import AuthService from '../authService';
import * as Actions from './authActions';

export const login = (email, password) => async (dispatch) => {
  await dispatch(Actions.initLogin());
  try {
    const { authorization } = (await AuthService.Login(email, password)).data;
    if (!authorization.user.dealers && !authorization.user.companies) {
      await dispatch(Actions.finishLogin(null, null, true));
      return;
    }

    Auth.saveLoginInfo(authorization.user, authorization.token);
    const permissions = Auth.definePermissions(authorization.user);
    Auth.savePermissionsInfo(permissions);

    if (
      !authorization.user.dealers
       && authorization.user.companies.length > 0
       && authorization.user.companies.some((c) => c.companyId === 'FIDIS')
    ) {
      authorization.user.dealers = (await AuthService.getDealers()).data;
    }

    dispatch(DealerOperations.setDealers(authorization.user.dealers));
    dispatch(DealerOperations.setSelectedFPlanDealer(authorization.user.dealers[0].dealerId));

    await dispatch(Actions.finishLogin(authorization.user, permissions, false));

    Tracking.Mixpanel.identifyUser(authorization.user.id);
    Tracking.Mixpanel.trackLoginAttempt(email, true);
  } catch (error) {
    Auth.clearAllCookies();

    await dispatch(Actions.finishLogin(null, null, true));
    Tracking.Mixpanel.trackLoginAttempt(email, false);
  }
};

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(Actions.setErrorMessage(message));
};

export const setSessionExpired = () => (dispatch) => {
  dispatch(Actions.setSessionExpired(true));
};

export const invalidateSession = () => (dispatch) => {
  Auth.clearAllCookies();
  Dealers.clearAllStorage();
  ResgateAceites.clearAllStorage();
  dispatch(Actions.setSessionExpired(false));
  dispatch(Actions.setUser(null));
  dispatch(Actions.setPermissions(null));
};

export const loginRedirect = (key) => async (dispatch) => {
  if (_.isUndefined(key) || _.isNull(key) || key === 'undefined') {
    return;
  }
  await dispatch(Actions.initLoginRedirect());
  try {
    const { user, token } = (await AuthService.loginRedirect(key)).data;

    if (!user.dealers && !user.companies) {
      await dispatch(Actions.finishLogin(null, null, true));
      return;
    }

    Auth.saveLoginInfo(user, token);
    const permissions = Auth.definePermissions(user);
    Auth.savePermissionsInfo(permissions);

    if (
      !user.dealers
      && user.companies.length > 0
      && user.companies.some((c) => c.companyId === 'FIDIS')
    ) {
      user.dealers = (await AuthService.getDealers()).data;
    }

    dispatch(DealerOperations.setDealers(user.dealers));
    dispatch(DealerOperations.setSelectedFPlanDealer(user.dealers[0].dealerId));

    await dispatch(Actions.finishLogin(user, permissions, false));

    Tracking.Mixpanel.identifyUser(user.id);
    Tracking.Mixpanel.trackLoginRedirectAttempt(user.email, true);
  } catch (error) {
    Auth.clearAllCookies();

    await dispatch(Actions.finishLogin(null, null, true));
    Tracking.Mixpanel.trackLoginRedirectAttempt('redirect from site fidis', false);
  }
};
