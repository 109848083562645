import React, {
  createContext, useContext, useEffect, useReducer, useCallback,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { applyProperty, applyPropertyArray } from 'utils/object';
import useCustomFetch from 'hooks/useFetch';
import duplicataService from '../services/duplicataService';

const initialState = (cnpj = null) => ({
  filters: {
    cnpj,
    duplicataChassi: null,
    valorNotaFiscal: 'all',
    dataInicioEntradaFidc: null,
    dataFinalEntradaFidc: null,
    dataInicioVencimento: null,
    dataFimVencimento: null,
    nomeColuna: null,
    sentidoOrdenacao: null,
    status: 'all',
    concessionaria: 'all',
  },

  pageParams: {
    page: 0,
    ipp: 25,
    total: 0,
  },

  requestStatus: {
    loading: false,
    success: false,
    error: null,
  },

  duplicataList: [],
  isUpdated: true,

  duplicataDrawer: {
    isOpen: false,
    duplicataIndex: -1,
    duplicataDetalhe: undefined,
    transition: false,
  },
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'dealer':
      return {
        ...state,
        filters: {
          ...state.filters,
          cnpj: action.cnpj,
        },
        isUpdated: true,
      };
    case 'filters':
      return {
        ...state,
        filters: applyPropertyArray(
          { ...state.filters },
          action.propertyNames,
          action.values,
        ),
      };
    case 'pagination':
      return {
        ...state,
        pageParams: applyProperty(
          { ...state.pageParams },
          action.propertyName,
          action.value,
        ),
      };
    case 'response':
      return {
        ...state,
        pageParams: {
          ...state.pageParams,
          total: _.isNumber(action.data.total) ? action.data.total : state.pageParams.total,
        },
        requestStatus: {
          ...action.requestStatus,
        },
        duplicataList: action.data?.duplicatas ?? [],
        isUpdated: _.isBoolean(action.data.isUpdated) ? action.data.isUpdated : state.isUpdated,
      };
    case 'drawer':
      return {
        ...state,
        duplicataDrawer: {
          ...state.duplicataDrawer,
          isOpen: action.isOpen,
          duplicataIndex: action.duplicataIndex,
          duplicataDetalhe: action.duplicataDetalhe,
          transition: action.isOpen ? true : state.duplicataDrawer.transition,
        },
      };
    case 'transition':
      return {
        ...state,
        duplicataDrawer: {
          ...state.duplicataDrawer,
          transition: !state.duplicataDrawer.transition,
        },
      };
    case 'reset_filters':
      return {
        ...state,
        filters: {
          ...state.filters,
          duplicataChassi: null,
          valorNotaFiscal: 'all',
          dataInicioEntradaFidc: null,
          dataFinalEntradaFidc: null,
          dataInicioVencimento: null,
          dataFimVencimento: null,
          status: 'all',
          concessionaria: 'all',
        },
      };
    default: return state;
  }
};

const DuplicataContext = createContext(null);

export const DuplicataContextProvider = ({
  children, dealer, addSnackbar, closeSnackbar,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState(dealer.cnpj));
  useEffect(() => {
    dispatch({ type: 'dealer', cnpj: dealer.cnpj });
    dispatch({ type: 'filters', propertyNames: ['concessionaria'], values: [null] });
  }, [dealer]);

  const closeDrawer = useCallback(() => {
    dispatch({
      type: 'drawer', isOpen: false, duplicataIndex: -1, duplicataDetalhe: undefined,
    });
  }, []);

  const { filters, pageParams, duplicataDrawer } = state;
  const { cnpj } = filters;
  const { isOpen } = duplicataDrawer;

  const useGetDuplicatas = useCallback(
    async () => {
      if (isOpen) closeDrawer();
      return duplicataService.getDuplicatas(filters, pageParams);
    },
    [isOpen, closeDrawer, filters, pageParams],
  );

  const [response, getDuplicatas] = useCustomFetch(useGetDuplicatas, [cnpj]);
  useEffect(() => {
    const { data, ...requestStatus } = response;
    dispatch({ type: 'response', data, requestStatus });
  }, [response]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const duplicataContextStore = {
    state,
    dispatch,
    getDuplicatas,
    addSnackbar,
    closeSnackbar,
    closeDrawer,
    openDrawer: (value, index) => dispatch({
      type: 'drawer', isOpen: true, duplicataIndex: index, duplicataDetalhe: value,
    }),
    transitionDrawer: () => dispatch({ type: 'transition' }),
    setFilters: (propertyNames, values) => dispatch({ type: 'filters', propertyNames, values }),
    setPagination: (propertyName, value) => dispatch({ type: 'pagination', propertyName, value }),
    resetFilters: () => dispatch({ type: 'reset_filters' }),
  };

  return (
    <DuplicataContext.Provider value={duplicataContextStore}>
      {children}
    </DuplicataContext.Provider>
  );
};

DuplicataContextProvider.propTypes = {
  children: PropTypes.element,
  dealer: PropTypes.object.isRequired,
  addSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
};

DuplicataContextProvider.defaultProps = {
  children: null,
};

export const useDuplicataContext = () => useContext(DuplicataContext);
