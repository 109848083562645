import axios from 'axios';
import cookies from 'react-cookie';
import uuid from 'uuid';

import { reactLocalStorage } from 'reactjs-localstorage';

import { minutesFromNow } from 'utils/time';
import Dealers from '../../modules/dealers';
import AuthService from '../../modules/auth/authService';
import { AuthOperations } from '../../modules/auth/redux';
import store from '../store';
import { getCancellationToken } from './cancellation';
import { getEncryptedBody } from '../../modules/auth/auth';

import {
  COOKIE_AUTH_TOKEN,
  COOKIE_AUTH_TOKEN_VALIDATE,
  COOKIE_FIDIS_USER_ID,
  COOKIE_FIDIS_USER_EMAIL,
  COOKIE_FIDIS_DEALER_ID,
} from '../../modules/auth/cookies';

const addHeaders = (config) => {
  const newConfig = config;
  newConfig.headers.Authorization = reactLocalStorage.getObject(COOKIE_AUTH_TOKEN, null, true);
  newConfig.headers[COOKIE_FIDIS_USER_ID] = cookies.load(COOKIE_FIDIS_USER_ID);
  newConfig.headers[COOKIE_FIDIS_USER_EMAIL] = cookies.load(COOKIE_FIDIS_USER_EMAIL);

  if (!newConfig.headers['fidis-dealer-id']) {
    newConfig.headers[COOKIE_FIDIS_DEALER_ID] = Dealers.loadSelectedFPlanDealerId();
  }

  newConfig.headers['fidis-request-id'] = uuid.v1().toString().substring(0, 8);
  return newConfig;
};

const shouldRefreshToken = () => {
  const validate = cookies.load(COOKIE_AUTH_TOKEN_VALIDATE);
  return (validate - new Date().getTime() < 5 * 60 * 1000) && cookies.load('REFRESHING_TOKEN') !== '1';
};

const ExecuteTokenRefresh = (refreshFn) => {
  cookies.save('REFRESHING_TOKEN', '1', { maxAge: 60, path: '/', secure: true });
  setTimeout(async () => {
    refreshFn();
    cookies.remove('REFRESHING_TOKEN', { path: '/' });
  }, 1000);
};

const invalidateSession = () => {
  store.dispatch(AuthOperations.setSessionExpired());
};

const setErrorMessage = (message) => {
  store.dispatch(AuthOperations.setErrorMessage(message));
};

export function setupAxios() {
  axios.interceptors.request.use(
    (config) => {
      const newConfig = addHeaders(config);
      newConfig.cancelToken = getCancellationToken();
      if ((config.method === 'post' || config.method === 'put' || config.method === 'patch' || config.method === 'delete') && !(config.data instanceof FormData)) {
        newConfig.data = getEncryptedBody(config.data);
      }
      return newConfig;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    async (response) => {
      if (shouldRefreshToken()) {
        ExecuteTokenRefresh(async () => {
          const { token } = (await AuthService.Refresh(cookies.load(COOKIE_FIDIS_USER_EMAIL))).data;

          reactLocalStorage.setObject(COOKIE_AUTH_TOKEN, token);
          cookies.save(COOKIE_AUTH_TOKEN_VALIDATE, minutesFromNow(55).getTime(), { path: '/', secure: true });
        });
      }

      return Promise.resolve(response);
    },
    (error) => {
      const temp = error.response.request.responseURL.split('/');
      const route = `${temp[temp.length - 2]}/${temp[temp.length - 1]}`;
      if (route === 'authorize/login' && error.response && error.response.status === 401) {
        setErrorMessage(error.response.data.errorMessage);
      }

      if (error && error.response && error.response.status === 401) {
        const header = error.response.headers['security-token'];
        if (header && header === 'invalid') {
          invalidateSession();
        }
      }

      return Promise.reject(error);
    },
  );
}
