import logger from 'utils/logger';

import service from './service';
import actions from './actions';

const getLimitesAprovados = (codigoBuc) => async (dispatch) => {
  try {
    dispatch(actions.getLimitesAprovadosStart());

    const response = await service.getLimitesAprovados(codigoBuc);

    dispatch(actions.getLimitesAprovadosSuccess(response));
  } catch (e) {
    logger.error(e);
    dispatch(actions.getLimitesAprovadosError());
  }
};

const getDetalheLimite = (idLimite, detalhesLimite) => async (dispatch) => {
  try {
    dispatch(actions.getDetalheLimiteStart());

    const historicoLimite = await service.getHistoricoLimite(idLimite);

    dispatch(actions.getDetalheLimiteSuccess(detalhesLimite, historicoLimite));
  } catch (e) {
    logger.error(e);
    dispatch(actions.getDetalheLimiteError());
  }
};

const updateAndSaveStatus = (idLimite, codigoBuc, status) => async (dispatch, getState) => {
  const { user } = getState().authStore;
  try {
    dispatch(actions.updateLimiteStart());

    await service.updateAndSaveStatus(user.name, {
      status,
      idLimiteList: [idLimite],
      tipo: 'alterar_status',
      condicao: null,
      motivoCancelamento: null,
    });

    if (status === 'aprovacao_aceita_dealer') {
      dispatch(actions.addSnackbar('Aprovação aceita com sucesso.', 'success'));
    }
    if (status === 'aprovacao_recusada_dealer') {
      dispatch(actions.addSnackbar('Aprovação recusada com sucesso.', 'success'));
    }

    dispatch(getLimitesAprovados(codigoBuc));
    dispatch(actions.updateLimiteSuccess());
  } catch (e) {
    logger.error(e);
    dispatch(actions.updateLimiteError());
    dispatch(actions.addSnackbar('Erro ao enviar proposta', 'error'));
  }
};

const dismissSnackbar = (id) => (dispatch) => {
  dispatch(actions.dismissSnackbar(id));
};

const addSnackbar = (message, type) => (dispatch) => {
  dispatch(actions.addSnackbar(message, type));
};

const resetStore = () => (dispatch) => {
  dispatch(actions.resetStore());
};

const setCadastroPage = (cadastroPage) => (dispatch) => {
  dispatch(actions.setCadastroPage(cadastroPage));
};

const updateLimiteStatus = (statusLimite) => (dispatch) => {
  dispatch(actions.updateLimiteStatus(statusLimite));
};

export default {
  getLimitesAprovados,
  dismissSnackbar,
  addSnackbar,
  getDetalheLimite,
  updateAndSaveStatus,
  resetStore,
  setCadastroPage,
  updateLimiteStatus,
};
