const COOKIE_AUTH_TOKEN = 'token';
const COOKIE_AUTH_TOKEN_VALIDATE = 'token-validate';
const COOKIE_FIDIS_USER_ID = 'fidis-user-id';
const COOKIE_FIDIS_USER_EMAIL = 'fidis-user-email';
const COOKIE_FIDIS_USER_NAME = 'fidis-user-name';
const COOKIE_FIDIS_DEALER_ID = 'fidis-dealer-id';
const LS_AVAILABLE_DEALERS = 'fidis-available-dealers';
const LS_FIDIS_USER = 'fidis-user';
const COOKIE_FIDIS_USER_PERMISSIONS = 'fidis-user-permissions';

export {
  COOKIE_AUTH_TOKEN,
  COOKIE_AUTH_TOKEN_VALIDATE,
  COOKIE_FIDIS_USER_ID,
  COOKIE_FIDIS_USER_EMAIL,
  COOKIE_FIDIS_USER_NAME,
  COOKIE_FIDIS_DEALER_ID,
  LS_AVAILABLE_DEALERS,
  LS_FIDIS_USER,
  COOKIE_FIDIS_USER_PERMISSIONS,
};
