import { reactLocalStorage } from 'reactjs-localstorage';

const LS_DEALERS = 'dealers/localStorage/dealers';
const LS_FPLAN_DEALER = 'dealers/localStorage/selectedFPlanDealer';

export const saveDealers = (dealers) => {
  reactLocalStorage.setObject(LS_DEALERS, dealers);
};

export const loadDealers = () => (
  reactLocalStorage.getObject(LS_DEALERS, [], true)
);

export const saveSelectedFPlanDealerId = (dealerId) => {
  reactLocalStorage.setObject(LS_FPLAN_DEALER, dealerId);
};

export const loadSelectedFPlanDealerId = () => (
  reactLocalStorage.getObject(LS_FPLAN_DEALER, null, true)
);

export const clearAllStorage = () => {
  reactLocalStorage.remove(LS_DEALERS);
  reactLocalStorage.remove(LS_FPLAN_DEALER);
};
