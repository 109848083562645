import axios from 'axios';

class TotalService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
  }

  async getSaldoAtualizado(cnpj) {
    const params = new URLSearchParams();
    params.append('cnpj', cnpj);

    const response = await axios.get(`${this.URL_API}/fidc/total/saldo-atualizado`, { params });

    if (!response || response.status !== 200) {
      throw new Error(`Error get saldo atualizado ${cnpj}`);
    }

    return response.data;
  }
}

export default new TotalService();
